@import 'style';

$colorMouse: rgba(white, 0.5);
$widthMouse: 26px;
$heightMouse: 40px;
$borderMouse: 2px;
$posMouse: 10px;

$colorTrackball: white;
$sizeTrackball: 8px;
$posTrackball: 25px;
$shrinkTrackball: 0.25;

.mousescroll {
	cursor: pointer;
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: $widthMouse;
	height: $heightMouse;
	border-radius: 50px;
	border: $borderMouse solid $colorMouse;
	transition: border 0.3s ease-out;
	animation: nudgeMouse 2s ease-out infinite;

	&:after {
		content: '';
		position: absolute;
		top: 25%;
		left: 0;
		right: 0;
		margin: 0 auto;
		background-color: $colorTrackball;
		width: $sizeTrackball;
		height: $sizeTrackball;
		border-radius: 100%;
		animation: trackBallSlide 2s ease-out infinite;
	}

	&:hover {
		border-color: white;
		animation: none;

		&:after {
			animation: none;
		}
	}
}

@keyframes trackBallSlide {
	0% {
		opacity: 1;
		transform: scale(1) translateY(0);
	}
	25% {
		opacity: 0;
		transform: scale($shrinkTrackball) translateY($posTrackball);
	}
	50% {
		opacity: 0;
		transform: scale(1) translateY(0);
	}
	100% {
		opacity: 1;
		transform: scale(1) translateY(0);
	}
}

@keyframes nudgeMouse {
	0% {
		transform: translateY(0);
	}
	25% {
		transform: translateY($posMouse);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(0);
	}
}
